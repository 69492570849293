<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_308_487)">
            <path
                d="M14.7564 2.06845C13.8062 1.2777 12.7024 0.692326 11.5148 0.349229C10.3271 0.00613223 9.08123 -0.087254 7.85568 0.0749549C5.58848 0.369493 3.51834 1.51583 2.06562 3.28121C0.612897 5.04659 -0.113518 7.29867 0.0338722 9.58017C0.181263 11.8617 1.19141 14.0016 2.85921 15.5653C4.527 17.1291 6.72742 17.9996 9.01368 18H14.2502C15.2444 17.9988 16.1975 17.6033 16.9005 16.9003C17.6035 16.1973 17.999 15.2442 18.0002 14.25V8.48171V8.43446C17.9208 7.20854 17.5922 6.01169 17.0345 4.91712C16.4767 3.82254 15.7016 2.85322 14.7564 2.06845ZM6.00018 5.24996H9.00018C9.19909 5.24996 9.38986 5.32897 9.53051 5.46962C9.67116 5.61028 9.75018 5.80104 9.75018 5.99996C9.75018 6.19887 9.67116 6.38963 9.53051 6.53029C9.38986 6.67094 9.19909 6.74996 9.00018 6.74996H6.00018C5.80127 6.74996 5.6105 6.67094 5.46985 6.53029C5.3292 6.38963 5.25018 6.19887 5.25018 5.99996C5.25018 5.80104 5.3292 5.61028 5.46985 5.46962C5.6105 5.32897 5.80127 5.24996 6.00018 5.24996ZM12.0002 12.75H6.00018C5.80127 12.75 5.6105 12.6709 5.46985 12.5303C5.3292 12.3896 5.25018 12.1989 5.25018 12C5.25018 11.801 5.3292 11.6103 5.46985 11.4696C5.6105 11.329 5.80127 11.25 6.00018 11.25H12.0002C12.1991 11.25 12.3899 11.329 12.5305 11.4696C12.6712 11.6103 12.7502 11.801 12.7502 12C12.7502 12.1989 12.6712 12.3896 12.5305 12.5303C12.3899 12.6709 12.1991 12.75 12.0002 12.75ZM12.0002 9.74996H6.00018C5.80127 9.74996 5.6105 9.67094 5.46985 9.53029C5.3292 9.38963 5.25018 9.19887 5.25018 8.99996C5.25018 8.80104 5.3292 8.61028 5.46985 8.46963C5.6105 8.32897 5.80127 8.24996 6.00018 8.24996H12.0002C12.1991 8.24996 12.3899 8.32897 12.5305 8.46963C12.6712 8.61028 12.7502 8.80104 12.7502 8.99996C12.7502 9.19887 12.6712 9.38963 12.5305 9.53029C12.3899 9.67094 12.1991 9.74996 12.0002 9.74996Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_308_487">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
